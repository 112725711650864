// extracted by mini-css-extract-plugin
export var apply = "job-listing-module--apply--05189";
export var container = "job-listing-module--container--973a7";
export var content = "job-listing-module--content--3d4bc";
export var desc = "job-listing-module--desc--fc965";
export var formSection = "job-listing-module--formSection--b628a";
export var icon = "job-listing-module--icon--8d7b3";
export var line = "job-listing-module--line--ff983";
export var link = "job-listing-module--link--d2577";
export var otherSites = "job-listing-module--otherSites--7a1f0";
export var section = "job-listing-module--section--7ceee";
export var text = "job-listing-module--text--7aefd";
export var thinLine = "job-listing-module--thinLine--fdb2a";
export var title = "job-listing-module--title--bcbd6";