import React, { useState, useRef } from "react"
import * as styles from "./DropZone.module.css"

type DropZoneProps = {
  accept: string
  placeholder?: string
  errorMessage?: string
  onChange: (file: File) => void
}

export default function DropZone({
  accept = ".pdf",
  placeholder = "Drop file here or click to choose",
  errorMessage = "File not allowed",
  onChange,
}: DropZoneProps) {
  const [isDragOver, setIsDragOver] = useState(false)
  const [error, setError] = useState(null)
  const [file, setFile] = useState(null)
  const inputRef = useRef(null)

  function validateFileType(file: File): boolean {
    const isValid = new RegExp(accept.replace("*", ".*")).test(file.type)
    setError(isValid ? null : errorMessage)
    return isValid
  }

  const onFileChange = event => {
    const currentFile = event.target.files[0]

    if (validateFileType(currentFile)) {
      setFile(event.target.files[0])
      onChange(currentFile)
    }
  }

  const onFileDrop = event => {
    event.preventDefault()
    setIsDragOver(false)

    let currentFile

    if (event.dataTransfer.items) {
      currentFile = event.dataTransfer.items[0].getAsFile()
    } else {
      currentFile = event.dataTransfer.files[0].getAsFile()
    }

    if (validateFileType(currentFile)) {
      setFile(currentFile)
      onChange(currentFile)
    }
  }

  const onClick = () => {
    inputRef.current.click()
  }

  const onDragOver = event => {
    event.preventDefault()
  }

  const onDragEnter = () => {
    setIsDragOver(true)
  }

  const onDragLeave = () => {
    setIsDragOver(false)
  }

  return (
    <button
      className={`${styles.dropZone} ${error && styles.error} ${
        isDragOver && styles.dragOver
      }`}
      onClick={onClick}
      onDrop={onFileDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      aria-label={placeholder}
    >
      {error &&
        (isDragOver ? (
          <p className={styles.placeholder}>Drop file here</p>
        ) : (
          <p className={styles.error}>{error}</p>
        ))}

      {!error &&
        (file ? (
          <p>{file.name}</p>
        ) : (
          <p className={styles.placeholder}>{placeholder}</p>
        ))}

      <input
        id="fileInput"
        type="file"
        accept={accept}
        tabIndex={-1}
        style={{ display: "none" }}
        ref={inputRef}
        onChange={onFileChange}
      />
    </button>
  )
}
