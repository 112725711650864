import React, { Component, SyntheticEvent } from "react"
import Recaptcha from "react-recaptcha"
import { InView } from "react-intersection-observer"
import DropZone from "../../../components/DropZone"
import officesData from "econify/data/officesdb.json"
import * as styles from "./JobForm.module.css"
import Checkbox from "econify/components/Checkbox"
import { CONTACT_SUBMISSION_URL } from "econify/contstants"

interface IErrorMessage {
  selectedFile?: string
  locations?: string
  authorizedLocation?: string
  workOnSite?: string
}

interface IJobFormState {
  email: string
  locations: string[]
  optionalMessage: string
  recaptchaToken: string
  errorsMsg: IErrorMessage
  selectedFileError: boolean
  locationsError: boolean
  submitting: boolean
  sendSuccessful: boolean
  submitMsg: string
  close: boolean
  disableSubmit: boolean
  selectedFile: File
  authorizedLocation: boolean
  workOnSite: boolean
  authorizedLocationError: boolean
  workOnSiteError: boolean
}

const LOCATIONS = { ...officesData.locations }

class JobForm extends Component<{}, IJobFormState> {
  state = {
    email: "",
    locations: ["new-york"],
    optionalMessage: "",
    recaptchaToken: "",
    errorsMsg: {
      selectedFile: null,
      locations: null,
      authorizedLocation: null,
      workOnSite: null,
    },
    selectedFileError: false,
    locationsError: false,
    submitting: false,
    sendSuccessful: false,
    submitMsg: "",
    close: false,
    disableSubmit: true,
    selectedFile: null,
    authorizedLocation: false,
    workOnSite: false,
    authorizedLocationError: false,
    workOnSiteError: false,
  }

  componentWillUnmount() {
    const scriptId = "recaptcha-script"
    let script = document.getElementById(scriptId)
    if (script) {
      script.remove()
    }
  }

  handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()

    if (this.state.recaptchaToken && this.validateForm()) {
      this.setState({ submitting: true })

      const preferredLocations = this.state.locations.reduce((prev, curr) => {
        return `${prev} \`${LOCATIONS[curr]}\``
      }, "")

      const formData = new FormData()

      formData.append(
        "file",
        this.state.selectedFile,
        this.state.selectedFile?.name
      )

      formData.append(
        "initial_comment",
        `\n*Preferred location*: ${preferredLocations}\n>${this.state.optionalMessage}\n`
      )

      formData.append("recaptchaToken", this.state.recaptchaToken)

      fetch(CONTACT_SUBMISSION_URL, {
        method: "POST",
        body: formData,
      }).then((response: any) => {
        if (response.status !== 200) {
          this.setState({
            submitMsg: "Error sending application. Please try again later.",
            close: true,
            sendSuccessful: false,
            submitting: false,
          })

          return
        }

        this.resetForm()
        this.setState({
          submitMsg: `Application sucessfully sent. We'll be in touch shortly!`,
          close: true,
          sendSuccessful: true,
          submitting: false,
        })
      })
    }
  }

  validateForm = (): boolean | void => {
    const { selectedFile, locations, workOnSite, authorizedLocation } =
      this.state
    const errors: IErrorMessage = {}

    if (!workOnSite) {
      errors.workOnSite =
        "We're only accepting applicants who are able (and excited) to join us in office."
    }

    if (!authorizedLocation) {
      errors.authorizedLocation =
        "We're only accepting applicants who do not require sponsorship to work in the location they have applied for."
    }

    if (!selectedFile) {
      errors.selectedFile = "Please attach a resume :)"
    }

    if (!locations.length) {
      errors.locations = "Please choose at least one location :)"
    }

    this.setState({
      errorsMsg: errors,
      selectedFileError: !!errors.selectedFile,
      locationsError: !!errors.locations,
      authorizedLocationError: !!errors.authorizedLocation,
      workOnSiteError: !!errors.workOnSite,
    })

    return !Object.entries(errors).length
  }

  resetForm = (): void => {
    this.setState({
      locations: ["new-york"],
      optionalMessage: "",
      selectedFile: null,
      workOnSite: false,
      authorizedLocation: false,
    })
  }

  onLocationsChange = (event: SyntheticEvent): void => {
    const { checked, value } = event.target as HTMLInputElement
    let copy = [...this.state.locations]
    if (checked) {
      copy = [...this.state.locations, value]
    } else {
      copy.splice(this.state.locations.indexOf(value), 1)
    }

    this.setState({ locations: copy })
  }

  onOptionalMessageChange = (event: SyntheticEvent): void => {
    this.setState({
      optionalMessage: (event.target as HTMLTextAreaElement).value,
    })
  }

  onauthorizedLocationChange = (event: SyntheticEvent): void => {
    this.setState({
      authorizedLocation: !this.state.authorizedLocation,
    })
  }

  onWorkOnSiteChange = (event: SyntheticEvent): void => {
    this.setState({
      workOnSite: !this.state.workOnSite,
    })
  }

  recaptchaLoaded = (): void => {}

  verifyCallback = (recaptchaToken: string): JSX.Element | void => {
    if (recaptchaToken) {
      this.setState({ recaptchaToken, disableSubmit: false })
    } else {
      this.setState({ disableSubmit: true })
    }
  }

  expiredCallback = (): void => {
    this.setState({ recaptchaToken: "", disableSubmit: true })
  }

  closeSelectedFileHandler = (): void => {
    this.setState({ selectedFileError: false })
  }

  closeLocationsHandler = (): void => {
    this.setState({ locationsError: false })
  }

  closeauthorizedLocationHandler = (): void => {
    this.setState({ authorizedLocationError: false })
  }

  closeWorkOnSiteHandler = (): void => {
    this.setState({ workOnSiteError: false })
  }

  closeSuccessfulHandler = (): void => {
    this.setState({ close: false })
  }

  successfulMessage = (): JSX.Element => {
    if (!this.state.submitMsg.length) {
      return <div className={styles.isHidden}>{this.state.submitMsg}</div>
    } else {
      const color = this.state.sendSuccessful ? "success" : "error"
      return (
        <div className={this.state.close ? styles[color] : styles.isHidden}>
          {this.state.submitMsg}
        </div>
      )
    }
  }

  loadScript() {
    const scriptId = "recaptcha-script"
    if (window !== undefined) {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script")
        script.id = scriptId
        script.src = "https://www.google.com/recaptcha/api.js?render=explicit"
        window.document.body.appendChild(script)
      }
    }
  }

  render(): JSX.Element {
    const {
      selectedFileError,
      locationsError,
      sendSuccessful,
      submitting,
      authorizedLocationError,
      workOnSiteError,
    } = this.state
    const submitBtnProperties = (submitting && { disabled: true }) || {}

    return (
      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) this.loadScript()
        }}
      >
        <div className={styles.section}>
          {sendSuccessful ? <h2>Thank you</h2> : <h2>Let's Connect</h2>}
          <hr />
          <div className={styles.desc}>
            {sendSuccessful ? (
              <p>We will take a look and be in touch as soon as we can.</p>
            ) : (
              <p>Interested? We want to hear from you.</p>
            )}
          </div>
          <div className={styles.wrap}>
            <div className={styles.info}>
              <p>
                <strong>Apply here</strong>
              </p>
              <hr />
            </div>
            {sendSuccessful && (
              <div className={styles.form}>{this.successfulMessage()}</div>
            )}
            {!sendSuccessful && (
              <form
                onSubmit={this.handleSubmit}
                method="POST"
                className={styles.form}
              >
                <div
                  className={
                    selectedFileError
                      ? styles.error
                      : [styles.error, styles.isHidden].join(" ")
                  }
                >
                  {this.state.errorsMsg.selectedFile}
                  <span
                    className={styles.errorBtn}
                    onClick={this.closeSelectedFileHandler}
                  >
                    x
                  </span>
                </div>
                <div
                  className={
                    locationsError
                      ? styles.error
                      : [styles.error, styles.isHidden].join(" ")
                  }
                >
                  {this.state.errorsMsg.locations}
                  <span
                    className={styles.errorBtn}
                    onClick={this.closeLocationsHandler}
                  >
                    x
                  </span>
                </div>
                <div
                  className={
                    authorizedLocationError
                      ? styles.error
                      : [styles.error, styles.isHidden].join(" ")
                  }
                >
                  {this.state.errorsMsg.authorizedLocation}
                  <span
                    className={styles.errorBtn}
                    onClick={this.closeauthorizedLocationHandler}
                  >
                    x
                  </span>
                </div>
                <div
                  className={
                    workOnSiteError
                      ? styles.error
                      : [styles.error, styles.isHidden].join(" ")
                  }
                >
                  {this.state.errorsMsg.workOnSite}
                  <span
                    className={styles.errorBtn}
                    onClick={this.closeWorkOnSiteHandler}
                  >
                    x
                  </span>
                </div>
                <div>
                  <label>Preferred Locations:</label>
                  <fieldset className={styles.locations}>
                    {Object.entries(LOCATIONS).map(
                      ([locationKey, locationName]) => (
                        <Checkbox
                          id={locationKey}
                          onChange={this.onLocationsChange}
                          checked={this.state.locations.includes(locationKey)}
                          label={locationName}
                        />
                      )
                    )}
                  </fieldset>
                </div>
                <div>
                  <input
                    aria-label="optional message"
                    className={styles.formControl}
                    value={this.state.optionalMessage}
                    placeholder="Optional message"
                    onChange={this.onOptionalMessageChange}
                  />
                </div>
                <Checkbox
                  small
                  onChange={this.onauthorizedLocationChange}
                  checked={this.state.authorizedLocation}
                  id="authorizedLocation"
                  label="I am legally authorized to work in the location I have
                    applied for and do not require sponsorship for employment
                    now or in the future."
                />
                <Checkbox
                  small
                  onChange={this.onWorkOnSiteChange}
                  checked={this.state.workOnSite}
                  id="workOnSite"
                  label="I currently reside near one of Econify's home offices (NYC,
                    SEA, ATX, BHX) and am ready and able to work on site as
                    needed."
                />
                <div className={styles.dropZone}>
                  <DropZone
                    accept=".pdf"
                    placeholder="Drag resume here or click to choose file"
                    errorMessage="File not allowed: Please choose a PDF file"
                    onChange={selectedFile => this.setState({ selectedFile })}
                  />
                </div>
                <div className={styles.btnRow}>
                  <button
                    type="submit"
                    className={styles.submitBtn}
                    {...submitBtnProperties}
                    disabled={this.state.disableSubmit || this.state.submitting}
                  >
                    SEND
                  </button>
                  <div className={styles.recaptcha}>
                    <Recaptcha
                      sitekey={process.env.SITE_KEY}
                      render="explicit"
                      onloadCallback={this.recaptchaLoaded}
                      verifyCallback={this.verifyCallback}
                      expiredCallback={this.expiredCallback}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </InView>
    )
  }
}

export default JobForm
