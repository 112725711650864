import React from "react"
import { graphql, withPrefix } from "gatsby"
import { micromark } from "micromark"

import Micromark from "../components/Micromark"
import Header from "../components/Header"
import * as styles from "./job-listing.module.css"
import JobForm from "../routes/Careers/JobForm"

const JobListing = ({ data }) => {
  const job = data.careersJson
  return (
    <div className={styles.container}>
      <Header isPageNav={true} />
      <div className={styles.content}>
        <Micromark
          className={(styles.text, styles.title)}
          markdown={job.role}
        />

        <div className={styles.text}>
          <strong>Job Type:</strong>
          <Micromark as="span" markdown={job.jobType} />
        </div>

        <div className={styles.text}>
          <strong>Locations:</strong>
          <Micromark as="span" markdown={job.locations.join(" / ")} />
        </div>

        <div className={styles.text}>
          <strong>Technologies:</strong>
          <Micromark as="span" markdown={job.tech} />
        </div>

        <strong>Company &amp; Job Description</strong>
        <Micromark className={styles.text} markdown={job.description} />

        <strong>Requirements</strong>
        <Micromark
          className={styles.text}
          markdown={job.requirements.join(" \n")}
        />

        <strong>Benefits</strong>
        <Micromark
          className={styles.text}
          markdown={job.benefits.join(" \n")}
        />
      </div>
      <div className={styles.line}></div>

      <JobForm />
      <div className={styles.otherSites}>
        <div className={styles.thinLine}></div>
        <div className={styles.apply}>
          Connect with us on
          <a
            aria-label="Follow Econify on Twitter"
            href="https://twitter.com/econify"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img
              alt="Econify Twitter"
              src={withPrefix("/images/social/twitter.svg")}
              className={styles.icon}
            />
          </a>
          <a
            aria-label="Follow Econify on LinkedIn"
            href="https://www.linkedin.com/company/econify"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img
              alt="Econify LinkedIn"
              src={withPrefix("/images/social/linkedin.svg")}
              className={styles.icon}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default JobListing

export const query = graphql`
  query ($jobId: String!) {
    careersJson(id: { eq: $jobId }) {
      id
      jobType
      role
      locations
      tech
      description
      requirements
      benefits
    }
  }
`
